import each from 'lodash-es/each';
import lazySizes from 'lazysizes';
import WOW from 'wow.js';
import gsap from 'gsap';
import Plyr from 'plyr';
import { tns } from 'tiny-slider/src/tiny-slider';

lazySizes.cfg.lazyClass = 'lazy';

const Ui = {
    slider: {
        instances: [],
        settings: {
            loop: true,
            duration: 400,
            easing: 'cubic-bezier(.17,.67,.32,1.34)',
        },
    },
};

Ui.init = () => {
    Ui.sliders();
    Ui.videos();
    Ui.scrollAnimations();
    Ui.barbers();
};

Ui.videos = () => {
    const player = new Plyr('.video-player');
}

Ui.sliders = () => {
    const sliders = document.querySelectorAll('.slider');

    if (! sliders) {
        return;
    }

    each(sliders, () => {
        tns({
            mode: 'gallery',
            container: '.slider',
            autoplay: true,
            controls: false,
            speed: 1000,
            arrowKeys: true,
            mouseDrag: true,
            swipeAngle: 180,
        });
    })
};

Ui.scrollAnimations = () => {
    const wow = new WOW({
        offset: 100,
        boxClass: 'media',
        callback: ($el) => {
            const tl = gsap.timeline();

            const d = 1;
            const e = 'cubic';

            const $cover = $el.querySelector('.media__cover');
            const $media = $el.querySelectorAll('.media__element:not(.media__element--overlay)');

            tl.fromTo($cover, {
                xPercent: 100,
                autoAlpha: 1,
            }, {
                xPercent: 0,
                ease: `${e}.out`,
                duration: d * 0.5,
                delay: 0.25 + parseFloat($el.dataset.delay),
            });

            tl.add('middle');

            tl.set($media, {
                autoAlpha: 1,
            });

            tl.fromTo($media, {
                scale: 1.5,
            }, {
                scale: 1,
                duration: 4,
                ease: 'expo.out',
            });

            tl.to($cover, {
                xPercent: -100,
                ease: `${e}.in`,
                duration: d * 0.5,
            }, 'middle');
        },
    });

    return wow.init();
}

Ui.barbers = () => {
    const elements = document.querySelectorAll(['.grid--barber .tabs__nav-list', '.grid--barber .btn--appointment'])

    elements.forEach(el => {
        const imageBlock = el.closest('.grid--barber').querySelector('.card-block--image');

        if (! imageBlock) {
            return;
        }

        el.addEventListener('mouseenter', e => {
            imageBlock.classList.add('-hover');
        })

        el.addEventListener('mouseleave', e => {
            imageBlock.classList.remove('-hover');
        })
    })
}

export default Ui;
