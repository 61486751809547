import Cookies from 'js-cookie';

const CookieBar = {
    name: 'cookie-bar',
    cookie: null,
    daysValidFor: 30,
    el: {
        root: null,
        alert: null,
        dismiss: null,
    },
};

/**
 * Initialize the cookie bar
 */
CookieBar.init = () => {
    CookieBar.cookie = Cookies.get(CookieBar.name);

    CookieBar.el.root = document.querySelector('.cookie-bar');

    if (!CookieBar.el.root) {
        return;
    }

    CookieBar.el.dismiss = CookieBar.el.root.querySelector('.cookie-bar__dismiss');

    CookieBar.addEventListeners();
};

/**
 * Any event listeners relating to the cookie bar
 */
CookieBar.addEventListeners = () => {
    CookieBar.el.dismiss.addEventListener('click', CookieBar.dismiss);
};

/**
 * Dismiss the cookie bar
 */
CookieBar.dismiss = (e) => {
    e.preventDefault();

    CookieBar.el.root.classList.add('-hidden');

    Cookies.set(CookieBar.name, 1, {
        expires: CookieBar.daysValidFor,
    });
};

/**
 * Checks whether the cookie bar has been seen or not yet
 *
 * @returns {boolean}
 */
CookieBar.hasNotBeenSeenYet = () => {
    if (!CookieBar.cookie) {
        return true;
    }

    return false;
};

export default CookieBar;
