const Tabs = {
    activeClass: '-active',
    selector: {
        root: '.tabs',
        navItem: '.tabs__nav__item',
        content: '.tabs__content',
        contentItem: '.tabs__content__item',
    },
};

Tabs.init = () => {
    const instances = document.querySelectorAll(Tabs.selector.root);

    if (! instances.length) {
        return;
    }

    instances.forEach(Tabs.setup);
};

Tabs.setup = (el) => {
    el.querySelectorAll('.tabs__nav a')
        .forEach(el => {
            el.addEventListener('click', Tabs._showTab)
        });

    //  Open the first tab
    el
        .querySelector(Tabs.selector.navItem)
        .querySelector('a')
        .click();
}

Tabs._showTab = (e) => {
    e.preventDefault();

    const el = e.target;
    const root = el.closest(Tabs.selector.root);
    const tabs = root.querySelectorAll(Tabs.selector.navItem);
    const items = root.querySelectorAll(Tabs.selector.contentItem)
    const contentToShow = root.querySelector(el.getAttribute('href'));

    //  Toggle active class on tab
    tabs.forEach(el => el.classList.remove(Tabs.activeClass));
    el.parentElement.classList.add(Tabs.activeClass);

    //  Show/hide content
    if (! items.length) {
        return;
    }

    items.forEach(el => el.classList.remove(Tabs.activeClass));

    if (contentToShow) {
        contentToShow.classList.add(Tabs.activeClass);
    }
}

export default Tabs;
