import each from 'lodash-es/each';
import Helpers from './Helpers';

const Maps = {
    instance: {
        root: null,
        markers: null,
    },
    icons: {
        selector: '.map_icon',
    },
};

Maps.init = () => {
    each(document.querySelectorAll('.map'), Maps.newInstance);
};

/*
 * This function will render a Google Map onto the selected jQuery element
 *
 * @param  el
 * @return null
 */
Maps.newInstance = (el) => {
    const markers = el.querySelectorAll('.marker');
    const icons = document.querySelectorAll(Maps.icons.selector);

    const zoom = el.dataset.zoom ? Number(el.dataset.zoom) : 12;

    const args = {
        scrollwheel: false,
        zoom,
        center: new google.maps.LatLng(0, 0),
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        // zoomControl: false,
        mapTypeControl: false,
        // streetViewControl: false,
        // fullscreenControl: false,
        styles: require('./map-styles.json'),
    };

    if (window.MAP_STYLES && window.MAP_STYLES.length) {
        args.styles = window.MAP_STYLES;
    }

    const map = new google.maps.Map(el, args);

    map.markers = [];

    //  Add Makers
    each(markers, (marker) => {
        Maps.addMarker(marker, map);
    });

    //  Add event listeners to toggle the map icons
    each(icons, (icon) => {
        icon.addEventListener('click', e => Maps.toggleMapIcons(e, map));
    });

    Maps.centerMap(map);

    return map;
};

/**
 * @param e
 * @param map
 */
Maps.toggleMapIcons = (e, map) => {
    const target = e.currentTarget;
    const iconsToShow = target.dataset.activity;

    each(document.querySelectorAll(Maps.icons.selector), (el) => {
        el.classList.remove('-active');
    });

    target.classList.add('-active');

    each(map.markers, (marker) => {
        const visibility = marker.classList.contains('-static') || (marker.activity === iconsToShow);

        marker.setVisible(visibility);
    });
};

/*
 * This function will add a marker to the selected Google Map
 *
 * @param  marker
 * @param  map (Google Map object)
 * @return null
 */
Maps.addMarker = (marker, map) => {
    const latlng = new google.maps.LatLng(marker.dataset.lat, marker.dataset.lng);

    const i = {
        url: marker.dataset.icon ? marker.dataset.icon : null,
        scaledSize: new google.maps.Size(50, 61),
    };

    const m = new google.maps.Marker({
        position: latlng,
        map,
        icon: i,
        panX: !Helpers.isMobile() ? marker.dataset.panX : 0,
        panY: !Helpers.isMobile() ? marker.dataset.panY : 0,
        title: marker.dataset.title ? marker.dataset.title : null,
        activity: marker.dataset.activity ? marker.dataset.activity : null,
        classList: marker.classList,
    });

    if (marker.dataset.activity) {
        m.setVisible(false);
    }

    map.markers.push(m);

    const infoWindow = new google.maps.InfoWindow({
        content: marker.innerHTML,
    });

    if (marker.innerHTML) {
        google.maps.event.addListener(m, 'click', () => {
            infoWindow.open(map, m);
        });

        google.maps.event.addListener(map, 'click', () => {
            if (infoWindow) {
                infoWindow.close();
            }
        });
    }
};

/*
 * This function will center the map, showing all markers attached to this map
 *
 * @param  map (Google Map object)
 * @return null
 */
Maps.centerMap = (map) => {
    const bounds = new google.maps.LatLngBounds();

    each(map.markers, (marker) => {
        const latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());
        bounds.extend(latlng);
    });

    if (map.markers.length === 1) {
        const marker = map.markers[0];

        // set center of map
        map.setCenter(bounds.getCenter());
        map.setZoom(17);

        if (marker.panX || marker.panY) {
            const pan = {
                x: marker.panX ? marker.panX : 0,
                y: marker.panY ? marker.panY : 0,
            };

            map.panBy(pan.x, pan.y);
        }
    } else {
        map.fitBounds(bounds);
    }
};

export default Maps;
