import gsap from 'gsap';

const Navigation = {
    el: {
        root: null,
        toggle: null,
    },
};

Navigation.init = () => {
    Navigation.primaryNav();
};

Navigation.primaryNav = () => {
    Navigation.el.root = document.querySelector('.nav--primary');
    Navigation.el.checkbox = document.getElementById('nav-checkbox');
    Navigation.el.overlay = document.querySelector('.nav-overlay');
    Navigation.el.items = Navigation.el.root.querySelectorAll('a');

    if (!Navigation.el.root) {
        return;
    }

    document.addEventListener('keyup', e => {
        if (e.key.toUpperCase() === 'ESCAPE') {
            Navigation.el.checkbox.checked = false;
            Navigation.toggle();
        }
    });

    document.addEventListener('turbolinks:click', () => {
        Navigation.el.checkbox.checked = false;
        Navigation.toggle();
    });

    Navigation.el.overlay.addEventListener('click', () => {
        Navigation.el.checkbox.checked = false;
        Navigation.toggle();
    });

    Navigation.el.checkbox.addEventListener('change', () => {
        Navigation.toggle();
    });
};

Navigation.toggle = () => {
    const state = Navigation.el.checkbox.checked;

    gsap.to(Navigation.el.root, {
        x: `${state ? 0 : 110}%`,
        ease: `cubic.${state ? 'out' : 'in'}`,
        duration: 0.5,
        force3D: true,
        onComplete: () => {
            if (!state) {
                gsap.set(Navigation.el.items, {
                    opacity: 0,
                    delay: 0.5,
                });
            }
        },
    });

    if (state) {
        gsap.fromTo(Navigation.el.items, {
            x: '100px',
            opacity: 0,
        }, {
            x: '0px',
            opacity: 1,
            duration: 1,
            ease: 'expo.out',
            stagger: 0.07,
        });
    }
}

export default Navigation;
