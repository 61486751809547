//
//  Module — Interactions
//

import each from 'lodash-es/each';
import {gsap} from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);

/* eslint-disable */
const Interactions = {
    //
};

Interactions.init = () => {
    Interactions.scrollTo();
    Interactions.links();
    Interactions.turbolinks();
};

Interactions.turbolinks = () => {
    const fader = document.querySelector('.page-fader');

    if (fader) {
        fader.classList.add('fade-out');
    }

    document.addEventListener('turbolinks:visit', () => {
        fader.classList.remove('fade-out');
    })
};

/**
 * Scroll to an element on the page
 * To implement, add a data-scroll=".element" attribute
 *
 * @return void
 */
Interactions.scrollTo = () => {
    each(document.querySelectorAll('[data-scroll]'), (el) => {
        el.addEventListener('click', (e) => {
            e.preventDefault();

            let scrollTo;
            let offset;

            if (el.dataset.scroll === 'top') {
                gsap.to(window, {
                    duration: 0.75,
                    scrollTo: 0,
                });
            } else {
                scrollTo = el.dataset.scroll;
                offset = el.dataset.scrollOffset ? el.dataset.scrollOffset : 0;

                if (document.querySelector(el.dataset.scroll)) {
                    gsap.to(window, {
                        duration: 0.75,
                        ease: 'power1.inOut',
                        scrollTo: {
                            y: scrollTo,
                            offsetY: offset,
                        },
                    });
                }
            }
        });
    });
};

Interactions.links = () => {
    const externalLinks = document.querySelectorAll('a.external, a[rel="external"], a[href$=".pdf"]');
    const elementLinks = document.querySelectorAll('[data-link]');

    each(externalLinks, (el) => {
        el.addEventListener('click', (e) => {
            if (e.which !== 2) {
                window.open(e.currentTarget.href);
                e.preventDefault();
            }
        });
    });

    each(elementLinks, (el) => {
        el.addEventListener('click', (e) => {
            e.preventDefault();

            if (el.classList.contains('external')) {
                window.open(el.dataset.link);
                return;
            }

            window.location = el.dataset.link;
        })
    });
};
/* eslint-enable */

export default Interactions;
