import gsap from 'gsap';

const Hero = {
    ticking: false,
    $root: null,
    $el: null,
};

Hero.init = () => {
    Hero.$root = document.querySelector('.hero');
    Hero.$inner = Hero.$root.querySelector('.hero__inner');
    Hero.$el = Hero.$root.querySelector('.hero__inner img, .hero__inner video');

    const max = 1.05;
    const mid = 1;

    gsap.fromTo(Hero.$el, {
        scale: mid,
    }, {
        scale: max,
        opacity: 1,
        duration: 2,
        delay: 0.5,
        ease: 'cubic.out',
    });

    document.addEventListener('scroll', () => {
        if (window.scrollY < Hero.$inner.offsetHeight) {
            Hero.tick();
        }
    });
};

Hero.tick = () => {
    const max = 1;
    const min = 0.95;

    if (!Hero.ticking) {
        requestAnimationFrame(() => {
            gsap.set(Hero.$inner, {
                scale: ((window.scrollY * (min - max)) / Hero.$inner.offsetHeight) + max,
                opacity: ((window.scrollY * -1) / Hero.$inner.offsetHeight) + 1,
                yPercent: window.scrollY / Hero.$inner.offsetHeight * 30,
                force3D: true,
            });

            Hero.ticking = false;
        });
    }

    Hero.ticking = true;
};

export default Hero;
