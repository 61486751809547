/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    require('bootstrap/js/dist/collapse');
    require('bootstrap/js/dist/modal');
} catch (e) {
    // eslint-disable-next-line
    console.log(e);
}
