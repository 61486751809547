import Turbolinks from 'turbolinks';

import Interactions from './modules/Interactions';
import Maps from './modules/Maps';
import Navigation from './modules/Navigation';
import Ui from './modules/Ui';
import Hero from './modules/Hero';
import CookieBar from './modules/CookieBar';
import Tabs from './modules/Tabs';

Turbolinks.start();

/* eslint-disable-next-line global-require */
require('./bootstrap');

const ready = () => {
    Ui.init();
    Hero.init();
    Interactions.init();
    Maps.init();
    CookieBar.init();
    Navigation.init();
    Tabs.init();
};

if (document.readyState === 'loading') {
    // document.addEventListener('DOMContentLoaded', ready);
    document.addEventListener('turbolinks:load', ready);
} else {
    ready();
}
